import { Button, Container, Header, Aside, Main, Footer, Carousel, CarouselItem, Link } from 'element-ui'
import Vue from 'vue'

Vue.use(Button)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Link)
