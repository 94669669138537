<template>
<el-container>
  <el-header>
    <div class="nav-bar">
      <div class="logo-box">
        <img class="logo" src="./assets/logo.png" />
        <span class="sologen">北京中科荣晟能源装备有限公司</span>
      </div>
      <div class="oper-area">
        <el-link href="https://gas.zkrsyun.com" type="error">进入气站管理系统</el-link>
      </div>
    </div>
  </el-header>
  <el-main>
    <el-carousel :interval="4000" type="card" height="400px">
      <el-carousel-item >
        <img class="item-image" src="./assets/usage/U01.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/usage/U02.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/usage/U03.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/usage/U04.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/usage/U05.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/usage/U06.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/usage/U07.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/usage/U08.jpg" />
      </el-carousel-item>
    </el-carousel>
    <el-carousel :interval="4000" type="card" height="400px">
      <el-carousel-item >
        <img class="item-image" src="./assets/products/P01.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/products/P02.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/products/P03.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/products/P04.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/products/P05.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/products/P06.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/products/P07.jpg" />
      </el-carousel-item>
      <el-carousel-item >
        <img class="item-image" src="./assets/products/P08.jpg" />
      </el-carousel-item>
    </el-carousel>
  </el-main>
  <el-footer>京ICP备2020048217号</el-footer>
</el-container>
</template>
<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.el-header, .el-footer {
  background-color: royalblue;
  color: #FFFFFF;
  text-align: center;
  line-height: 60px;
}
.nav-bar {
  display: flex;
  justify-content: space-between;
}
.logo-box {
  display: flex;
  align-items: center;
}
.logo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.sologen {
  margin-left: 10px;
  font-size: 24px;
  color: #FFFFFF;
}
.el-link {
  color: #FFFFFF;
  font-size: 22px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.item-image {
  width: 100%;
  height: 100%;
}
</style>
